.video-gallery-wrapper {
  background-color: var(--primary-background-color);
  margin-top: 48px;
  padding: 0 24px; /* Padding for better alignment */
}

/* Adjust space between rows */
.video-row {
  display: flex;
  justify-content: center;
  gap: 48px; /* Space between videos */
  margin-bottom: 48px; /* Increased space between rows */
  flex-wrap: wrap; /* Allow wrapping for better mobile support */
}

/* Thumbnail styling */
.thumbnail {
  cursor: pointer;
  transition: transform 0.3s ease;
  margin-bottom: 8px; /* Add some margin between thumbnail and content wrapper */
}

/* Landscape and portrait thumbnail sizes */
.thumbnail.landscape {
  width: 720px;
  height: 405px;
}

.thumbnail.portrait {
  width: 360px;
  height: 640px;
}

/* Image styling */
.thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

/* Hover effects */
.thumbnail:hover {
  transform: scale(1.05);
}

/* Play button on hover */
.play-button-thumbnail {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none; /* Hide the button by default */
}

.thumbnail:hover .play-button-thumbnail {
  display: block;
}

.video-content-wrapper {
  color: var(--primary-text-color);
  /*margin-left: 24px;*/
  margin-top: 32px;
  text-align: center;
  display: flex;
  flex-direction: column;
  line-height: 20px;
}

.video-content-title {
  font-weight: 500;
}

.video-content-category {
  font-style: italic;
}

.expanded-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5); /* Slightly darker background */
  backdrop-filter: blur(10px); /* Adds a subtle blur to the background */
  z-index: 1000;
}

.expanded-video::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    135deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0.3) 100%
  );
  z-index: 1;
  pointer-events: none; /* Ensure the gradient does not interfere with user interaction */
}

.expanded-video video {
  position: relative;
  z-index: 2; /* Ensure the video is above the overlay */
  width: auto;
  height: 100%;
  max-height: 100vh;
  object-fit: contain;
  border-radius: 10px; /* Optional: Add a slight border radius to the video */
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5); /* Adds a shadow for depth */
}


/* Responsive adjustments */
@media (max-width: 1024px) {
  .thumbnail.landscape {
    width: 100%; /* Full width on smaller screens */
    height: auto; /* Maintain aspect ratio */
  }

  .thumbnail.portrait {
    width: 100%; /* Full width on smaller screens */
    height: auto; /* Maintain aspect ratio */
  }

  /* Ensure proper wrapping for mobile */
  .video-row {
    flex-wrap: wrap;
    gap: 24px; /* Reduce space between videos for smaller screens */
  }
}
