.videoWrapper {
  position: relative;
  min-height: 50px;
  height: 100%;
}

.control-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  display: none; /* Hide the controls by default */
  gap: 20px;
}

.videoWrapper:hover .control-wrapper {
  display: flex; /* Show the controls on hover */
}

.play-button {
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.7);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
}

.stop-button {
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.7);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1001;
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}