.video-intro-container {
  position: relative;
  height: 120vh; /* Ensures scrolling is possible */
}

.video-intro-inner-content {
  position: relative;
  height: 120vh;
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  text-align: center;
}

.name-wrapper {
  position: absolute;
  z-index: 2;
  top: 4%;
  width: 40%;
  font-size: var(--extra-large-font-size);
  font-family: var(--secondary-font-family);
  display: flex;
  justify-content: center;
  flex-direction: column;
  transition: top 0.8s ease-out;
}

.video-container {
  position: absolute;
  top: 10%;
  left: auto;
  max-width: 100%;
  max-height: 100%;
  z-index: 1;
  transition: top 0.5s, height 0.5s;
  display: flex;
  justify-content: center;
}

.intro-video {
  width: 40%;
  height: 40%;
  object-fit: cover;
  transition: opacity 0.5s;
  border-radius: 32px;
}

.about-content {
  height: 120%;
  padding: 16px;
}

.about-content-paragraph {
  padding: 8px 128px;
  line-height: 28px;
  text-align: justify;
}

.about-footer-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-footer {
  width: 60px;
  height: 60px;
}

@media (max-width: 680px) {
  .name-wrapper {
    font-size: var(--large-font-size);
  }

  .about-content-paragraph {
    padding: 8px 20px;
  }

    .intro-video {
        width: 80%;
        height: 80%;
    }
}
