:root {
  --font-family: "Barlow", sans-serif;
  --secondary-font-family: 'Aesthetic';
  --primary-text-color: #191918;
  --secondary-text-color: #bd292b;
  --tertiary-text-color: #FFFEF5;
  --primary-background-color: #FFFEF5;
  --extra-large-font-size: 60px;
  --large-font-size: 38px;
  --title-font-size: 20px;
  --text-font-size: 16px;
  --secondary-text-font-size: 12px;
}

.App {
  background-color: var(--primary-background-color);
  width: 100vw;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App {
  color: var(--primary-text-color);
}