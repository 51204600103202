.home-main-video-wrapper {
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
  height: calc(100vh - 80px);
  object-fit: cover;
  background-color: var(--primary-background-color);
}

.home-main-video-title {
  color: var(--tertiary-text-color);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-family: var(--secondary-font-family);
}

.home-main-video-name {
  font-size: var(--extra-large-font-size);
}

.home-main-video-divider {
  margin: 16px 0;
}

.home-main-video-occupation {
  font-size: var(--large-font-size);
}

.home-scroll-wrapper {
  margin: 0 auto;
  display: block;
  border-radius: 48px;
  border: 2px solid var(--tertiary-text-color);
  height: 50px;
  width: 25px;
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
}

.scroll {
  position: absolute;
  background-color: var(--tertiary-text-color);
  height: 10px;
  width: 10px;
  border-radius: 50%;
  left: 50%;
  transform: translateX(-50%);
  animation: scrollDown 1.5s linear infinite;
}

@keyframes scrollDown {
  0% {
    transform: translate(-50%, 10px);
    opacity: 0;
  }
  50% {
    transform: translate(-50%, 20px);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, 50px);
    opacity: 0;
  }
}

.home-scroll-text {
  position: absolute;
  bottom: 2%;
  left: 50%;
  transform: translateX(-50%);
  color: var(--tertiary-text-color);
  font-size: var(--text-font-size);
}

@media (max-width: 680px) {
  .home-main-video-name {
    font-size: 36px;
  }

  .home-main-video-occupation {
    font-size: var(--title-font-size);
  }
}

.trusted-by-section {
  gap: 24px;
  padding: 48px;
  display: flex;
  flex-direction: column;
  margin-left: 80px;
  margin-right: 80px;
}

.brand-list-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 48px;
}

.brand-title {
  text-align: center;
  font-size: 32px;
  margin-bottom: 32px;
}

.brand-item {
  position: relative;
  width: 160px;
  height: 120px;
  display: inline-block;
}

.brand-item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* Mobile layout */
@media (max-width: 768px) {
  .trusted-by-section {
    margin-left: 48px;
    margin-right: 48px;
  }

  .brand-item {
    width: 120px; /* Reduce width for mobile */
    height: 70px; /* Adjust height for smaller screens */
  }

  .brand-list {
    justify-content: space-around; /* Center logos more evenly on mobile */
    gap: 12px; /* Adjust the gap for smaller screens */
  }
}

/* Smaller mobile layout */
@media (max-width: 480px) {
  .trusted-by-section {
    margin-left: 8px;
    margin-right: 8px;
  }

  .brand-item {
    width: 100px; /* Even smaller width for very small screens */
    height: 60px; /* Adjust height accordingly */
  }

  .brand-list {
    justify-content: space-evenly; /* More spacing on smaller mobile devices */
    gap: 4px; /* Smaller gap on very small screens */
  }
}
