.portfolio-wrapper {
  height: calc(100vh - 80px);
}

.portfolio-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.portfolio-content-title {
  font-size: var(--extra-large-font-size);
  margin-top: 60px;
  margin-bottom: 36px;
  font-weight: bold;
  font-family: var(--secondary-font-family);
}

.portfolio-content-paragraph {
  width: 620px;
  font-size: var(--title-font-size);
  line-height: 36px;
  text-align: justify;
}

.progress-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 6px;
  background: var(--secondary-text-color);
  transform-origin: 0;
}

@media (max-width: 680px) {
  .portfolio-content-title {
    font-size: var(--large-font-size);
  }

  .portfolio-content-paragraph {
    width: 320px;
    font-size: var(--secondary-text-font-size);
  }
}
