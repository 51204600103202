@font-face {
  font-family: 'Aesthetic'; /* Name you'll use to reference the font */
  src: url('./fonts/Aesthetic.ttf') format('truetype'); /* Path to your font file */
}

body {
  font-family: var(--font-family);
  margin: 0;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
  height: 6px;
}

/* Style the scrollbar thumb (draggable part) */
::-webkit-scrollbar-thumb {
  background-color: var(--secondary-text-color); /* Color of the thumb */
  border-radius: 3px; /* Round the corners of the thumb */
}

/* Style the scrollbar track */
::-webkit-scrollbar-track {
  background-color: var(--tertiary-text-color); /* Color of the track */
  border-radius: 10px; /* Round the corners of the track */
}

/* Style the corner where the vertical and horizontal scrollbars meet */
::-webkit-scrollbar-corner {
  background-color: var(--tertiary-text-color); /* Color of the corner */
}
