.header-wrapper {
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 3;
  background: rgba(255, 254, 245, 0.8);
  margin: 0 16px;
}

.header-logo {
  width: 80px;
  height: 80px;
  cursor: pointer;
}

.header-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  font-size: var(--text-font-size);
  font-weight: 400;
  font-style: normal;
}

.header-divider {
  margin-left: 8px;
  margin-right: 8px;
}

.header-insta {
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin-top: 2px;
  margin-left: 24px;
}

.header-placeholder {
  width: 16px;
}

/*styles for link hover */
a {
  background-image: linear-gradient(to right, #bd292b, #972022 50%, #000 50%);
  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  margin: 0 24px;
  position: relative;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
}

a:before {
  content: '';
  background: var(--secondary-text-color);
  display: block;
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 0;
  height: 2px;
  transition: all 0.3s ease-in-out;
}

a:hover {
  background-position: 0;
  cursor: pointer;
}

a:hover::before {
  width: 100%;
}

@media (max-width: 680px) {
  .header-wrapper {
    margin: 0 8px;
  }
  .header-link {
    font-size: var(--secondary-text-font-size);
  }

  .header-divider {
    margin-left: 2px;
    margin-right: 2px;
  }

  .header-placeholder {
    width: 8px;
  }

  a {
    margin: 0 8px;
  }
}
